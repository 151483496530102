import React from 'react';
import { useTranslation } from 'react-i18next';
import { Controller } from 'react-hook-form';
import { FormControl, FormHelperText, styled, Box } from '@mui/material';
import { InputSelectV2 } from '@insights-ltd/design-library/components';

type SelectProps = {
  id: string;
  name: string;
  label: string;
  control: any;
  error: boolean;
  errorMessage: string;
  children: React.ReactNode;
  autoComplete?: string;
  required?: boolean;
};

const StyledFormControl = styled(FormControl)({
  '&:not(:last-child)': {
    marginBottom: '1rem',
  },
});

export const Select: React.FunctionComponent<SelectProps> = ({
  id,
  name,
  label,
  control,
  error,
  errorMessage,
  children,
  autoComplete,
  required = true,
}) => {
  const { t } = useTranslation();
  const StyledError = styled(FormHelperText)({
    marginTop: '5px !important',
    marginLeft: 0,
  });

  const labelElement = (
    <Box>
      {label}
      &nbsp;
      {required ? (
        <Box
          component="span"
          sx={(theme) => ({
            fontSize: '1rem',
            fontWeight: 400,
            lineHeight: '1.75rem',
            color: theme.palette.error.main,
          })}
          aria-hidden="true"
        >
          {t('ui.io.about_you.required_field')}
        </Box>
      ) : (
        <Box
          component="span"
          sx={(theme) => ({
            fontSize: '1rem',
            fontWeight: 400,
            lineHeight: '1.75rem',
            color: theme.palette.grey[600],
          })}
        >
          {t('ui.io.about_you.optional_field')}
        </Box>
      )}
    </Box>
  );

  return (
    <StyledFormControl variant="outlined" error={error} fullWidth>
      <Controller
        render={({ field: { ref, value, ...rest } }) => (
          <InputSelectV2
            id={id}
            labelText={labelElement}
            labelId={`${id}-label`}
            data-testid={`${name}-select`}
            error={error}
            SelectDisplayProps={{
              'aria-describedby': error ? `${id}-error-label` : '',
              'aria-labelledby': `${id}-label`,
              'aria-required': 'true',
              'aria-invalid': error ? 'true' : 'false',
              role: 'combobox',
            }}
            autoComplete={autoComplete}
            inputRef={ref}
            {...rest}
            value={value}
            sx={{ width: '100%' }}
          >
            {children}
          </InputSelectV2>
        )}
        name={name}
        // defaultValue=""
        rules={{ required: true }}
        control={control}
      />
      {error ? (
        <StyledError id={`${id}-error-label`}>{errorMessage}</StyledError>
      ) : null}
    </StyledFormControl>
  );
};
