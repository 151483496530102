import React from 'react';
import { Box, Stack, Theme, useMediaQuery, useTheme } from '@mui/material';
import Logo from 'components/Svgs/InsightsLogo';
import { H1, LanguageSelect } from '@insights-ltd/design-library/components';
import { Evaluator } from 'types/evaluator';
import { useTranslation } from 'react-i18next';
import { MODEL_UI_SUPPORTED_LOCALE } from 'variables/dialects';

type HeaderProps = {
  title?: string;
  model?: Evaluator;
  languageSelect?: boolean;
};

const DefaultHeader = ({
  title,
  model,
  languageSelect = true,
}: HeaderProps) => {
  const mediumScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.up('md'),
  );
  const theme = useTheme();
  const { i18n } = useTranslation();
  const onLangSelect = async (language: string) => {
    await i18n.changeLanguage(language);
  };
  const languageOptions = MODEL_UI_SUPPORTED_LOCALE(model);

  return (
    <Box sx={{ borderBottom: 1, borderColor: 'grey.400' }}>
      <Stack
        translate="no"
        direction="row"
        height="4.5rem"
        justifyContent="space-between"
        alignItems="center"
        px={4}
      >
        <Logo
          data-testid="logo-svg"
          fill={theme.palette.blue.main}
          height="2rem"
          title="Insights Logo"
          aria-label="Insights"
        />
        <div>
          {mediumScreen && title && <H1 variant="body-bold">{title}</H1>}
        </div>
        <Box minWidth="10rem">
          {languageSelect ? (
            <LanguageSelect
              id="evaluator-language-select"
              onChange={(event) => onLangSelect(event.target.value as string)}
              languageOptions={languageOptions}
            />
          ) : null}
        </Box>
      </Stack>
    </Box>
  );
};

export default DefaultHeader;
