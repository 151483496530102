import React from 'react';
import { useTranslation } from 'react-i18next';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { MenuItem } from '@mui/material';
import { P } from '@insights-ltd/design-library/components';
import { AGES, COUNTRIES } from 'variables';
import { Evaluator } from 'types/evaluator';
import { setFocusOnFirstError } from 'utils/formHelpers';
import { Select } from './FormInputs';
import FormLayout from './FormLayout';
import FormNavigation from './FormNavigation';
import { Step3Data } from './types';
import {
  StyledFormInputWrapper,
  StyledPronounInfoWrapper,
} from './StyledComponents';

const Step3 = ({
  onNext,
  onBack,
  stepNumber,
  stepMax,
  userInfo,
  model,
}: {
  onNext: SubmitHandler<FieldValues>;
  onBack?: () => void;
  stepNumber: number;
  stepMax: number;
  userInfo: Step3Data;
  model: Evaluator;
}) => {
  const { t } = useTranslation();

  const {
    handleSubmit,
    control,
    formState: { errors },
    setFocus,
  } = useForm<FieldValues>({
    defaultValues: userInfo,
  });

  return (
    <FormLayout
      step={stepNumber}
      stepMax={stepMax}
      subtitle={t('ui.io.about_you.step_two.subtitle')}
      model={model}
    >
      <StyledFormInputWrapper
        onSubmit={handleSubmit(onNext, (submitErrors) => {
          setFocusOnFirstError(submitErrors, setFocus);
        })}
        noValidate
      >
        <Select
          id="countryOfResidence"
          name="countryOfResidence"
          label={t('ui.io.demographics.country.input_description')}
          control={control}
          error={Boolean(errors.countryOfResidence)}
          errorMessage={t('ui.io.about_you.error.country_required')}
          autoComplete="country"
        >
          <MenuItem disabled hidden value="" style={{ display: 'none' }}>
            {t('ui.io.about_you.please_select.placeholder')}
          </MenuItem>
          {COUNTRIES.map((textKey) => (
            <MenuItem disableRipple key={textKey} value={textKey}>
              {t(textKey)}
            </MenuItem>
          ))}
        </Select>
        <Select
          id="age"
          name="age"
          label={t('ui.io.demographics.age.input_description')}
          control={control}
          error={Boolean(errors.age)}
          errorMessage={t('ui.io.about_you.error.age_required')}
          autoComplete="age"
        >
          <MenuItem disabled hidden value="" style={{ display: 'none' }}>
            {t('ui.io.about_you.please_select.placeholder')}
          </MenuItem>
          {AGES.map((textKey) => (
            <MenuItem disableRipple key={textKey} value={textKey}>
              {t(textKey)}
            </MenuItem>
          ))}
        </Select>
        <StyledPronounInfoWrapper>
          <P color="textPrimary" variant="body-bold">
            {t('ui.io.onboarding.demographic_info.title')}
          </P>
          <P color="textSecondary">
            {t('ui.io.onboarding.demographic_info.body')}
          </P>
        </StyledPronounInfoWrapper>
        <FormNavigation onBack={onBack} />
      </StyledFormInputWrapper>
    </FormLayout>
  );
};

export default Step3;
